import React from "react";
import BannerExterior from "./component/BannerExterior";
import './App.css'
import {
	BrowserRouter as Router,
	Routes,
	Route,
} from "react-router-dom";
import AcdChecker from "./pages/acdchecker";
import Home from "./pages";
import NotFound from "./pages/404";

function Alpha() {
	return (
		<Router>
			<BannerExterior />
			<Routes>
				<Route path='*' element={<NotFound />} />
				<Route exact path="/" element={<Home />} />
				<Route path="/acdchecker" element={<AcdChecker />} />
			</Routes>
		</Router>
	);
}

export default Alpha;
