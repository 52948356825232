import React from "react";
import { useState, useEffect } from "react";
// import axios from "axios";
import Confetti from "react-confetti";
import { TwitterShareButton, TwitterIcon } from "react-share";



export default function Whitelist() {
	const [result, setResult] = useState("");
	const [showSuccess, setShowSuccess] = useState(false);

	const queryParameters = new URLSearchParams(window.location.search)
	const code = queryParameters.get("code")
	useEffect(() => {
		if (code) {
			const manageWallet = async (code) => {
				if (code) {
					try {
						const isEligible = await fetch(`https://api.acandogames.com/eligible/${code}`, {
							method: "GET",
							headers: {
								authorization: `${process.env.REACT_APP_AUTH_TYPE} ${process.env.REACT_APP_AUTH_TOKEN}`
							},
						});
						const eligibleData = (isEligible.status === 200) ? await isEligible.json() : { error: true };
						if (eligibleData.error) {
							console.log("eligibleData", eligibleData.error);
							return { error: true, message: "An error occured try again later." };
						}
						if (eligibleData.eligible === false) {
							return { error: false, eligible: false, message: 'Unfortunately, You are not eligible, stay tune in our twitter for having a chance to win' };
						}
						if (eligibleData.eligible === true) {
							return { error: false, eligible: true, message: 'Congrats, You are Eligible!' };
						}

					} catch (error) {
						console.log("catch", error);
						return { error: true, message: "An error occured try again later." };
					}
				}
			}
			manageWallet(code).then((data) => {
				setResult(data.message)
				if (data.error === false && data.eligible === true) {
					setShowSuccess(true)
				}
			})
		} else {
			setResult("Please log in to check your eligibility")
		}
	}, [code, setResult, setShowSuccess])
	return (
		<div id="#" className="relative  h-screen overflow-hidden">
			{showSuccess && (
				<Confetti
					width={window.innerWidth}
					height={window.innerHeight}
				// onConfettiComplete={setShowSuccess(false)}
				/>
			)}

			<div
				className=" h-full z-40  
   bg-fixed bg-backtwo bg-cover overflow-hidden "
			>
				<div className=" h-full z-40 pb-16 sm:pb-24 ">
					<main className="mt-36 content-center pt-20 mx-auto max-w-7xl px-4 sm:mt-36">
						<div className="text-center z-40 ">
							<h1 className="mt-2 text-5xl tracking-tight outline-2 sm:text-6xl font-bold md:text-7xl">
								{/* <span className="block tracking-tighter	 bg-clip-text  bg-gradient-to-br  text-transparent animate-gradient-x from-[#8139e6] to-[#76398c]  font-NewSpace">Whitelist Checker!</span>{'\n'} */}
								<span className="block  text-white acd">
									$ACD Airdrop Checker!
								</span>
							</h1>
							{/* <p className="z-40  aboslute  mt-3 max-w-md mx-auto text-base text-white sm:text-xl md:mt-5 md:text-2xl md:max-w-2xl dc">
								Check your account status by logging into your Discord account
							</p> */}
							<div class="mt-12  h-16 w-full flex justify-center space-x-4 items-center ">
								<a href="https://discord.com/oauth2/authorize?client_id=1166407544286216213&response_type=code&redirect_uri=https%3A%2F%2Facandogames.com%2Facdchecker&scope=identify">
									<button
										// onClick={SubmitWallet}
										// hover:scale-110
										className="check relative h-full w-48	 flex items-center justify-center px-8 py-3  text-base font-bold font-NewSpace  text-white bg-gradient-to-r from-violet-700 to-violet-500  hover:from-violet-500 hover:to-violet-700   md:py-4 md:text-lg   "
									>
										{/* <img className="w-full h-full absolute z-10" src={frame}></img> */}
										Log in
									</button>
								</a>
							</div>

							<p className="z-40  aboslute  mt-3 max-w-md mx-auto font-NewSpace  text-base text-white sm:text-xl md:mt-5 md:text-2xl md:max-w-2xl mb-6">
								{result}
							</p>
							{showSuccess && (
								<>
									<TwitterShareButton
										url={`https://www.acandogames/acd-airdrop`}
										title="I'm eligible for $ACD airdrop, Thank you @acando_ 
										check yours here below"
									>
										<TwitterIcon size={60} round={true} />
									</TwitterShareButton>

									<p className="z-40  aboslute  mt-6 max-w-md mx-auto font-NewSpace  text-base text-white sm:text-xl md:mt-5 md:text-2xl md:max-w-2xl mb-6">
										Share it on Twitter to have a chance for greater allocation.
									</p>
								</>
							)}
						</div>
					</main>
					{/* </div> */}
				</div>
			</div >
		</div >
	);
}
