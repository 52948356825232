import React from "react";
import Whitelist from "../component/Whitelist_1";

const AcdChecker = () => {
	return (
		<>
			<Whitelist />
		</>
	);
};

export default AcdChecker;