/* This example requires Tailwind CSS v2.0+ */
import { Disclosure } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import React from "react";
import { BsDiscord, BsTwitter } from "react-icons/bs";
import logo from "../assets/logo.png";

export default function BannerExterior() {
	const discord = () => {
		// alert("Great Shot!");
		window.open("https://discord.gg/4rJbqJhKyU", "_blank");
	};
	const twitter = () => {
		// alert("Great Shot!");
		window.open("https://twitter.com/acando_", "_blank");
	};

	//bg-gradient-to-r from-[#211547] to-[#291d46]
	return (
		<Disclosure
			as="nav"
			className=" opacity-90  backdrop-filter backdrop-blur-xl shadow fixed inset-x-0 z-50 border-b-2 border-b-gray-500 border-opacity-50"
		>
			{({ open }) => (
				<>
					<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
						<div className="flex justify-between h-20">
							<div className="flex">
								<div className="-ml-2 mr-2 flex items-center md:hidden">
									{/* Mobile menu button */}
									<Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-white-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
										<span className="sr-only">Open main menu</span>
										{open ? (
											<XIcon
												className="block h-8 w-8 text-blue-500"
												aria-hidden="true"
											/>
										) : (
											<MenuIcon
												className="block h-8 w-8 text-blue-500"
												aria-hidden="true"
											/>
										)}
									</Disclosure.Button>
								</div>
								<div className="flex-shrink-0 flex items-center">
									<img
										className="block lg:hidden h-8 w-auto"
										src={logo}
										alt="Workflow"
									/>
									<a href="/">
										<img src={logo} alt='logo' className="hidden lg:block h-20 w-auto" />
									</a>
								</div>
							</div>

							<div className="mr-20 flex items-center">
								<div className="flex-shrink-0 icons">
									<button
										type="button"
										onClick={discord}
										className="ml-2 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white  "
									>
										<BsDiscord className="h-6 w-6 m-2" aria-hidden="true" />
										{/* <PlusSmIconSolid className=" h-10 w-10" aria-hidden="true" /> */}
									</button>
									<button
										type="button"
										onClick={twitter}
										className="ml-2 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white  "
									>
										<BsTwitter className="h-6 w-6 m-2" aria-hidden="true" />
										{/* <PlusSmIconSolid className=" h-10 w-10" aria-hidden="true" /> */}
									</button>
								</div>
							</div>
						</div>
					</div>

					<Disclosure.Panel className="sm:hidden"></Disclosure.Panel>
				</>
			)}
		</Disclosure>
	);
}
