import React from "react";

const NotFound = () => {
	return (
		<div className=" relative h-screen overflow-hidden flex items-center justify-center">
			<h1 className="text-slate-50 text-3xl">Page Not Found</h1>
		</div>
	);
};

export default NotFound;