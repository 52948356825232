import React from "react";

const Home = () => {
	return (
		<div className=" relative h-screen overflow-hidden flex items-center justify-center">
			<h1 className="text-slate-50 text-3xl">Coming Soon!</h1>
		</div>
	);
};

export default Home;